import React from 'react'
import { Container, Row, Col, Button, Image } from 'react-bootstrap'
import irs_icon from '../../assets/icons/orange_icon.svg'
import directionArrow from '../../assets/icons/right_arrow_why_us.svg'
import x_icon from '../../assets/icons/x_false_positives_icon.svg'
import checkmark_icon from '../../assets/icons/check_accurate_results_icon.svg'

const WhyUsPage = ({ whyUsData }) => {
  const { title, subtitle, description } = JSON.parse(
    whyUsData?.firstSection.internal.content
  )
  const secondSection = JSON.parse(whyUsData?.secondSection.internal.content)
  const serviceLevel = JSON.parse(whyUsData?.serviceLevel.internal.content)
  const tagline = whyUsData?.tagline.internal.content
  const button = JSON.parse(whyUsData?.button.internal.content)
  const team = JSON.parse(whyUsData?.team.internal.content)

  return (
    <div className='why-us'>
      {/* First section */}
      <Container className='bg-header' fluid>
        <Container className='pb-2 pb-md-5 px-0'>
          <Row className='mb-3 mb-md-5'>
            <Col lg={6}>
              <h1>{title}</h1>
            </Col>
          </Row>
          <Row className='mb-2 mb-md-3'>
            <Col lg={9}>
              <p className='h5'>{subtitle}</p>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <p>{description}</p>
            </Col>
          </Row>
        </Container>
      </Container>
      {/* Second section: Intelligent Recommended Suggestions */}
      <Container className='mb-sm-3 mb-md-5 pb-5'>
        <Row className='my-5 py-md-5 my-md-5'>
          <Col xs={12} lg={1} className='pt-lg-2'>
            <Image
              className='irs-icon pb-sm-3 pb-2'
              src={irs_icon}
              height='80'
              width='80'
              fluid
              aria-hidden='true'
            ></Image>
          </Col>
          <Col md={12} lg={10}>
            <h2 className='mb-3'>{secondSection.title}</h2>
            <p>{secondSection.subtitle}</p>
          </Col>
        </Row>
        <Row>
          <Col className='text-center'>
            <p className='image-title'>{secondSection.body.govtSearch.title}</p>
            <Image
              className='w-100'
              src={whyUsData.trademarkSearchImages[0].file.url}
              aria-hidden='true'
            />
            <Image
              src={x_icon}
              height='30'
              width='30'
              fluid
              aria-hidden='true'
            />
            <p className='image-caption-title'>
              {secondSection.body.govtSearch.caption}
            </p>
            <p className='image-caption-description'>
              {secondSection.body.govtSearch.subcaption}
            </p>
          </Col>
          <Col className='text-center py-3' lg={1}>
            <Image
              className='direction-arrow-icon'
              src={directionArrow}
              aria-hidden='true'
            ></Image>
          </Col>
          <Col className='text-center'>
            <p className='image-title'>
              {secondSection.body.heirlumeSearch.title}
            </p>
            <Image
              className='w-100'
              src={whyUsData.trademarkSearchImages[1].file.url}
              width='600'
              aria-hidden='true'
            />
            <Image
              src={checkmark_icon}
              height='30'
              width='30'
              fluid
              aria-hidden='true'
            />
            <p className='image-caption-title'>
              {secondSection.body.heirlumeSearch.caption}
            </p>
            <p className='image-caption-description'>
              {secondSection.body.heirlumeSearch.subcaption}
            </p>
          </Col>
        </Row>
      </Container>
      {/* Service Level */}
      <Container fluid className='bg-ai-technology bg-img-shape py-lg-5 px-0'>
        <Row className='pb-md-5 mb-lg-5 mb-xl-0 d-flex flex-row-reverse'>
          <Col className='mt-lg-5' xs={12} lg={8}>
            <p className='tagline '>{tagline}</p>
          </Col>
        </Row>
        <Container className='mt-5'>
          <Row className='pt-lg-5'>
            <Col
              sm={12}
              lg={{ offset: 3, span: 7 }}
              xl={{ offset: 5, span: 6 }}
              className='mt-lg-5 mt-xl-0 pb-xl-5'
            >
              <h2 className='pt-md-5 mt-lg-5 mt-xl-0'>
                {serviceLevel.titleLineOne}
                <br></br>
                {serviceLevel.titleLineTwo}
              </h2>
              <p className='my-4 mb-5'>{serviceLevel.body}</p>
            </Col>
          </Row>
        </Container>
        {/* Our Values */}
        <Container className='py-sm-5 mb-5 mb-md-0'>
          <Row className='justify-content-md-center'>
            <Col md={12} className=''>
              <h2 className='pb-lg-5 text-left text-lg-center'>
                {whyUsData?.ourValuesTitle}
              </h2>
            </Col>
          </Row>
          <Row>
            {whyUsData?.ourValuesIcons.map((value, index) => {
              return (
                <Col
                  key={index}
                  className='col-12 col-xs-12 col-lg-4 px-lg-4 py-3'
                >
                  <div className='text-right text-lg-center mb-n4 mb-lg-0'>
                    <Image
                      className='values-icon '
                      src={value.file.url}
                      height='150'
                      width='123'
                      aria-hidden='true'
                    ></Image>
                  </div>
                  <p className='value-title mb-md-4 text-left text-lg-center'>
                    {value.title}
                  </p>
                  <p className='value-body text-left text-lg-center'>
                    {value.description}
                  </p>
                </Col>
              )
            })}
          </Row>
        </Container>
        {/* BUTTON Get a free, no-obligation quote in minutes */}
        <Container className='py-3 p-md-5'>
          <Row className='justify-content-center'>
            <Col md={5}>
              <Button
                as={'a'}
                href={`${button.url}`}
                className='btn-warning w-100 mb-4 mb-md-0'
              >
                {button.title}
              </Button>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container className='py-5'>
        {/* Our Technology Partners */}
        <Row className='py-md-5 justify-content-around'>
          {/* The styling of the clearco svg is a bit different hence the expressions */}
          <Col md={12}>
            <h2 className='text-center pb-4'>
              {whyUsData.technologyPartnersTitle}
            </h2>
          </Col>
          {whyUsData.technologyPartners.map((partner) => {
            return (
              <Col
                key={partner.description}
                className={`text-center ${
                  partner.description === 'clearco' ? 'align-self-center' : ''
                }`}
                md={12}
                lg={4}
              >
                <Image
                  fluid
                  className={`tech-partner my-3 px-md-2`}
                  src={partner.file.url}
                  alt={`${partner.description} logo`}
                ></Image>
              </Col>
            )
          })}
        </Row>
        {/* Expertise & Technology Working Together */}
        <section className='pb-5'>
          <hr></hr>
          <Container className='py-4 py-md-5 px-0'>
            <Row>
              <Col md={10} sm={12}>
                <h2>{team.title}</h2>
                <p className='py-3'>{team.body}</p>
              </Col>
              <Col md={2} className='text-right d-none d-sm-none d-md-inline'>
                <Image
                  src={whyUsData?.expertiseIcon.file.url}
                  height='80'
                  width='80'
                  aria-hidden='true'
                ></Image>
              </Col>
            </Row>
          </Container>
        </section>
      </Container>
    </div>
  )
}

export default WhyUsPage
